@font-face {
  font-family: 'icomoon';
  src:  url('Fonts/Icomoon/icomoon.eot?qsipxf');
  src:  url('Fonts/Icomoon/icomoon.eot?qsipxf#iefix') format('embedded-opentype'),
  url('Fonts/Icomoon/icomoon.ttf?qsipxf') format('truetype'),
  url('Fonts/Icomoon/icomoon.woff?qsipxf') format('woff'),
  url('Fonts/Icomoon/icomoon.svg?qsipxf#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-ico-check-list-chevron:before {
  content: "\e900";
}
.icon-ico-check-list:before {
  content: "\e901";
}
.icon-ico-console:before {
  content: "\e902";
}
.icon-ico-eye:before {
  content: "\e903";
}
.icon-ico-number:before {
  content: "\e904";
}
