body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.recursive-block-item {
    position: relative;
}

ul.recursive-block-item {
    margin: 0;
    padding-bottom: 10px
}

.recursive-block-item::before {
    content: '';
    display: block;
    background: repeating-linear-gradient(
            to bottom,
            #ffffff,
            #ffffff 5px,
            #556ee6 5px,
            #556ee6 10px
    ) repeat;

    position: absolute;
    width: 1px;
    left: 10px;
    top: 22px;
    bottom: 0;
    z-index: 1;
}

.recursive-block-item>*::after {
    content: '';
    display: block;
    background: repeating-linear-gradient(
            to right,
            #ffffff,
            #ffffff 5px,
            #556ee6 5px,
            #556ee6 10px
    ) repeat;

    position: absolute;
    top: 28px;
    width: 20px;
    left: -20px;
    height: 1px;
    z-index: 1;
}
